export default {
  adittionalScrollToTop: 10,
  adittionalScrollToBottom: 10,
  mainContainerWidth: 1232,
  pricingContainerWidth: 1160,
  backgroundImageWidth: 1540,
  devices: {
    mobile: '544px',
    tablet: '760px',
    desktop: '992px',
  },
  modals: {
    loginFormWidth: '400px',
    updatePasswordWidth: '400px',
  },
};
