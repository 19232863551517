import React, { useContext } from 'react';
import { graphql } from 'gatsby';

type RawTranslationType = {
  nodes: [{ [k: string]: string }];
};

export type TranslationType = {
  [k: string]: string;
};

export type TranslationVars = {
  [k: string]: string | number | null;
};

const TranslationContext = React.createContext(
  {} as { translations?: TranslationType; locale: string },
);

const varSubstituteRegex = /{{(\s*?)num(\s*?)}}/gm;

export const TranslationProvider = ({
  value: { translations, locale },
  children,
}: {
  value: { translations: RawTranslationType; locale: string };
  children: React.ReactNode;
}) => {
  const trans = translations?.nodes.reduce<TranslationType>(
    (acc, v) => ({ [v.key]: v.value, ...acc }),
    {},
  );

  return (
    <TranslationContext.Provider value={{ translations: trans, locale }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default () => {
  const { translations, locale } = useContext(TranslationContext);

  const exists = (key: string) => {
    if (!translations || !(key in translations)) {
      if (process.env.GATSBY_ENV !== 'production') {
        console.warn(
          translations ? `Missing translation key : ${key}` : 'Missing translation injection',
        );
      }

      return false;
    }

    return true;
  };

  const t = (key: string, vars?: TranslationVars) => {
    if (!translations || !exists(key)) {
      return key;
    }

    return !!vars
      ? Object.entries(vars).reduce(
          (str, [key, value]) =>
            str.replaceAll('{{' + key + '}}', value != null ? value.toString() : ''),
          translations[key],
        )
      : translations[key];
  };

  return {
    locale,
    exists,
    t,
    t2: (key: string, vars?: TranslationVars) => (
      <span dangerouslySetInnerHTML={{ __html: t(key, vars) }} />
    ),
    getTranslatedName: (
      type: 'spot' | 'zone',
      item?: {
        code?: string;
        name?: string;
        shortName?: string;
      },
      shortName: boolean = false,
    ) => {
      const key = `${type}_${item?.code || ''}${shortName ? '-short' : ''}`;

      return translations && key in translations
        ? t(key)
        : (shortName ? item?.shortName : item?.name) || '';
    },
  };
};

export const query = graphql`
  fragment Translations on DatoCmsTranslationConnection {
    nodes {
      key
      value
    }
  }
`;
