import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import SC from 'utils/style-constants';
import { c, FontSize, fs, r, Radius, s, Space } from 'utils/theme-helpers';
import CloseButton from '../CloseButton/close-button';
import Title from '../Title/title';

const sizes = {
  small: 550,
  medium: 650,
  large: 840,
};

const StyledModal = styled(ReactModal)`
  background: ${c('background')};
  border-radius: ${r(Radius.L)};
  outline: none;
  padding: ${s(Space.L)};
  position: relative;

  @media screen and (max-width: ${SC.devices.tablet}) {
    margin: ${s(Space.S)};
  }

  @media screen and (max-width: ${SC.devices.mobile}) {
    max-width: 100%;
    padding: ${s(Space.M)};
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 0;
  font-size: ${fs(FontSize.L)};
  padding-right: ${s(Space.M)};

  @media screen and (max-width: ${SC.devices.mobile}) {
    text-align: center;
    font-size: ${fs(FontSize.S)};
    line-height: ${fs(FontSize.S)};
    padding-left: ${s(Space.M)};
    margin-top: 0;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${s(Space.M)};

  @media screen and (max-width: ${SC.devices.mobile}) {
    padding: ${s(Space.S)};
  }
`;

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  hideCloseButton?: boolean;
  children: React.ReactNode;
  title?: string;
  size?: keyof typeof sizes;
};

function Modal({
  isOpen,
  onRequestClose,
  hideCloseButton = false,
  children,
  title,
  size = 'medium',
}: Props) {
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(22, 52, 87, 0.8)',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 100,
          overflowY: 'auto',
        },
        content: {
          width: size ? sizes[size] : undefined,
          maxHeight: '95%',
          overflowY: 'scroll',
        },
      }}
    >
      {title && <StyledTitle>{title}</StyledTitle>}
      {!hideCloseButton && <StyledCloseButton onClick={onRequestClose} />}
      {children}
    </StyledModal>
  );
}

export default Modal;
