import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { getAuth, signOut } from 'firebase/auth';
import JwtDecode from 'jwt-decode';
import { cache } from 'apollo/client';
import { EctorErrorType } from 'hooks/graphql-hooks';
import { onLogin, onLogout } from 'service/gtm';
import { getToken } from 'utils/token';
import { GET_ME } from '../apollo/queries/user-queries';
import { getItem, removeItem, setItem } from '../utils/local-storage-helpers';
import { useSSO } from './sso-hooks';
import { useLazyApi } from './use-api';

type LoginT = (token: string, refreshToken: string) => void;

export type MeDataT = {
  firstName: string;
  lastName: string;
  email: string;
  mainContact: {
    id: string;
    phone: string;
    clientProfile: {
      businessPartner: {
        name: string;
      };
      hasPaidBooking: boolean;
    };
  };
};

const LoginContext = createContext<[any, LoginT, () => void]>([null, () => {}, () => {}]);

export const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  const { token, decodedToken } = getToken();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!token);

  useEffect(() => {
    if (isLoggedIn && decodedToken) {
      onLogin({ email: decodedToken.username });
      datadogRum.setUser({
        email: decodedToken.username,
      });
    }
  }, []);

  const logout = async () => {
    removeItem('token');
    removeItem('refreshToken');
    setIsLoggedIn(() => false);
    cache.reset();
    onLogout(true);
    const auth = getAuth();
    await signOut(auth);
  };

  const login = (token: string, refreshToken: string) => {
    setItem('token', token);
    setItem('refreshToken', refreshToken);
    setIsLoggedIn(() => !!token);
  };

  return (
    <LoginContext.Provider value={[isLoggedIn, login, logout]}>{children}</LoginContext.Provider>
  );
};

/**
 * Return login tools
 */
export const useLogin = (
  options: {
    onSuccess?: () => void;
    onError?: () => void;
    isWebView?: boolean;
  } = {},
) => {
  const { onSuccess, onError, isWebView } = options;
  const [errors, setErrors] = useState<EctorErrorType[]>([]);
  const [isLoggedIn, login, logout] = useContext(LoginContext);
  const refreshToken = getItem('refreshToken');

  const handleLoginCompletion = (token: string, refreshToken: string) => {
    login(token, refreshToken);

    const decodedToken: { username: string } = JwtDecode(token);

    onLogin({ email: decodedToken.username });

    if (onSuccess) {
      onSuccess();
    }
  };

  const {
    checkEmail,
    loading: ssoLoading,
    signInWithPrivateIdentityProvider,
    status,
    setStatus,
    errors: ssoErrors,
    signInWithPublicIdentityProvider,
  } = useSSO({
    handleLoginCompletion,
    isWebView,
  });

  const { fetch: loginApi, isFetching } = useLazyApi(
    'login',
    ({ token, refreshToken }) => handleLoginCompletion(token, refreshToken),
    error => {
      setErrors([error]);
      onError?.();
    },
  );

  const { fetch: getRefreshToken } = useLazyApi(
    'refreshToken',
    ({ token, refreshToken }) => login(token, refreshToken),
    () => {
      logout();
    },
  );

  const postLogin = async (email: string, password: string) => {
    loginApi({ email, password, origin: 'front' });
  };

  const getTokenFromRefreshToken = async () => {
    const { token } = getToken();
    getRefreshToken({ refreshToken: refreshToken || '', token: token || '', origin: 'front' });
  };

  const { token, decodedToken } = getToken();

  return {
    // Login basic
    checkEmail,
    login: postLogin,
    logout,
    // Login SSO
    getTokenFromRefreshToken,
    signInWithPrivateIdentityProvider,
    signInWithPublicIdentityProvider,
    ssoStatus: status,
    setSsoStatus: setStatus,
    // data
    isLoggedIn,
    token,
    decodedToken,
    errors: errors.concat(ssoErrors),
    loading: ssoLoading || isFetching,
  };
};

/**
 * Return profile of the connected client
 */
export const useMe: () => MeDataT = () => {
  const { isLoggedIn } = useLogin();
  const [getMe, { data, refetch }] = useLazyQuery(GET_ME, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getMe().catch(console.error);
  }, []);

  useEffect(() => {
    if (isLoggedIn && refetch) {
      refetch();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && data?.me) {
      onLogin(data.me);
    }
  }, [isLoggedIn, data]);

  return isLoggedIn && data?.me;
};
