import '../styles/global.css';
import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import SC from 'utils/style-constants';
import { c, FontSize, FontWeight, fs, fw, r, Radius, s, Space } from 'utils/theme-helpers';
import { CookiesDialogGlobal } from './cookies';
import { ReactDatesGlobal } from './react-dates';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${CookiesDialogGlobal}
  ${ReactDatesGlobal}

  * {
    box-sizing: border-box;
  }

  body {
    color: ${c('primary')};
    font-size: ${fs(FontSize.S)};
    font-weight: ${fw(FontWeight.Regular)};
    line-height: 24px;
    width: 100%;
    height: 100%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: scroll; /* has to be scroll, not auto */
  }

  h1 {
    font-size: ${fs(FontSize.XL)};
    font-weight: ${fw(FontWeight.Bold)};
    line-height: 44px;
    margin-top: ${s(Space.L)};
    margin-bottom: ${s(Space.L)};
  }

  h2 {
    font-size: ${fs(FontSize.L)};
    font-weight: ${fw(FontWeight.Bold)};
    line-height: 32px;
    margin-top: ${s(Space.M)};
    margin-bottom: ${s(Space.M)};

    @media screen and (max-width: ${SC.devices.mobile}) {
      font-size: ${fs(FontSize.M)};
      line-height: 24px;
    }
  }

  h3 {
    font-size: ${fs(FontSize.M)};
    font-weight: ${fw(FontWeight.Regular)};
    line-height: 24px;
    margin-top: ${s(Space.S)};
    margin-bottom: ${s(Space.S)};
  }

  p {
    margin-top: ${s(Space.XS)};
    margin-bottom: ${s(Space.XS)};
  }

  span, button, ul {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  input, button, a.button {
    border: none;
    border-radius: ${r(Radius.L)};

    :focus {
      outline: 0;
    }
  }
  
  input {
    background: ${c('background')};

    ::placeholder {
      color: ${c('neutral')};
    }
  }

  input:disabled, button:disabled {
    cursor: not-allowed;
  }
  
  input::-ms-clear {
    display: none;
  }

  button, a {
    border: none;
    cursor: pointer;
    text-decoration: none;
  }

  button, a.button {
    line-height: initial;
    font-weight: ${fw(FontWeight.Bold)};
    display: inline-block;
    color: ${({ theme }) => theme.colors.primary};
  }

  a {
    color: ${c('action')};
  }
`;

export default GlobalStyle;
