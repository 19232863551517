import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password, origin: front) {
      token
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!, $oldPassword: String!) {
    updatePassword(password: $password, oldPassword: $oldPassword) {
      success
    }
  }
`;

export const CHECK_EMAIL = gql`
  mutation checkEmail($email: String!) {
    checkEmail(email: $email) {
      found
      standardSSO
      idSSO
    }
  }
`;

export const SWAP_TOKEN = gql`
  mutation swapToken($ssoToken: String!) {
    swapToken(sso_token: $ssoToken, origin: front) {
      token
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!, $token: String!) {
    refreshToken(refreshToken: $refreshToken, token: $token) {
      token
      refreshToken
    }
  }
`;
