export function setItem(field: string, value: string): void {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(field, value);
  }
}

export function getItem(field: string): string | null {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(field);
  }

  return null;
}

export function removeItem(field: string): void {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(field);
  }
}
