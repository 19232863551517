import React from 'react';
import styled, { ThemeColor } from 'styled-components';
import ClearIcon from 'components/icons/clearGreyThin';
import { c, s, Space } from 'utils/theme-helpers';

type ButtonProps = {
  color?: ThemeColor;
  noPadding?: boolean;
};

const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => !props.noPadding && s(Space.XS)(props)};

  & div,
  & svg {
    width: 18px;
    height: 18px;
  }

  path {
    stroke: ${props => c(props.color || 'neutral')(props)};
    stroke-width: 2px;
  }
`;

type CloseButtonProps = ButtonProps & React.HTMLProps<HTMLButtonElement>;

const CloseButton: React.FC<CloseButtonProps> = ({ ...props }: ButtonProps) => {
  return (
    <Button {...props}>
      <ClearIcon />
    </Button>
  );
};

export default CloseButton;
