import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import theme from 'assets/theme';
import { AlertProvider } from 'hooks/alert-hooks';
import { LoginProvider } from 'hooks/login-hooks';
import { ModalProvider } from 'hooks/modal-hooks';
import { BookingProvider } from 'hooks/use-booking';
import client from '../apollo/client';

// Must be a component to be able to use react hooks
export default ({ children }: { children: React.ReactNode }) => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LoginProvider>
          <BookingProvider>
            <ModalProvider>
              <AlertProvider>{children}</AlertProvider>
            </ModalProvider>
          </BookingProvider>
        </LoginProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
