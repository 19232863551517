import gql from 'graphql-tag';

export const CREATE_QUOTE = gql`
  mutation CreateQuote(
    $startAt: DateTime!
    $endAt: DateTime!
    $fromZone: String = null
    $toZone: String = null
    $fromSpot: String = null
    $toSpot: String = null
    $reason: BookingReason!
    $partner: String = null
    $contactId: String = null
    $discountCode: String = null
  ) {
    createQuote(
      startAt: $startAt
      endAt: $endAt
      fromZone: $fromZone
      fromSpot: $fromSpot
      toZone: $toZone
      toSpot: $toSpot
      reason: $reason
      partner: $partner
      origin: "front"
      contactId: $contactId
      discountCode: $discountCode
    ) {
      id
      fromZone {
        id
        code
        thirdParty {
          id
          code
        }
        maxHeight
      }
      toZone {
        id
        code
        thirdParty {
          id
          code
        }
        maxHeight
      }
      fromSpot {
        id
        code
        zone {
          code
          thirdParty {
            code
            name
          }
        }
        name
      }
      toSpot {
        id
        code
        zone {
          code
          thirdParty {
            code
            name
          }
        }
      }
      summaryPrices {
        parkingValet
        originalPrice
        totalPrice
        gift {
          name
          code
          amount
          rate
          hasOfferedService
        }
        serviceUpdates {
          code
          name
          description
          category
          weight
          isOffered
        }
        discountCode {
          amount
          rate
          code
        }
      }
      bookingUpdateInitial {
        dailyPrice
        shouldDisplayDailyPrice
      }
      startAt
      endAt
      dayDuration
      allowed
      disallowedReason
      needSpots
      alternatesStartDate
      alternatesEndDate
      isBusiness
      subscribedServices {
        code
        name
        description
        category
        weight
        price
        label
        picture
      }
      availableServices {
        code
        name
        description
        category
        weight
        price
        strikethroughPrice
        label
        picture
      }
      whiteLabelPartnerCode
    }
  }
`;

export const IMPOSSIBLE_BOOKING = gql`
  mutation ImpossibleBooking($bookingId: ID!, $email: String!, $phoneNumber: String!) {
    impossibleBooking(bookingId: $bookingId, email: $email, phoneNumber: $phoneNumber) {
      success
    }
  }
`;
