import React from 'react';
import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { initializeApp } from 'firebase/app';
import moment from 'moment';
import queryString from 'query-string';
import { ModalEnum, useModal } from 'hooks/modal-hooks';
import useTranslation from 'hooks/use-translation';
import 'react-dates/initialize';
import * as LocalStorage from 'utils/local-storage-helpers';
import ErrorBoundary from './error-boundary';
import GlobalStyle from './global-style';
import Modal from './storybook/UI/Modal/modal';

const initDatadog = () => {
  if (process.env.GATSBY_ENV && ['staging', 'production'].includes(process.env.GATSBY_ENV)) {
    datadogRum.init({
      applicationId: 'f01008e7-2f41-4726-a88a-ca1bbcc08ae5',
      clientToken: 'pub54c27bf6a07497e8e7b255b61f287715',
      site: 'datadoghq.eu',
      service: 'ector-front',
      env: process.env.GATSBY_ENV === 'production' ? 'prod' : 'staging',
      version: '1.0.0',
      sampleRate: 20,
      tracingSampleRate: 20,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [
        'https://api.ectorparking.com',
        'https://api-next.ectorparking.com',
        'https://pay.ectorparking.com',
        'https://pay-staging.ectorparking.com',
        'https://api.ectorfleet.com',
        'https://api-staging.ectorfleet.com',
        'https://api-v2.ectorparking.com/',
        'https://api-v2-staging.ectorparking.com',
        'https://authenticate.ectorparking.com',
        'https://auth.s.ector.io',
      ],
    });
    datadogRum.startSessionReplayRecording();
  }
};

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: 'https://auth-ector.firebaseio.com',
    projectId: 'auth-ector',
    storageBucket: 'auth-ector.appspot.com',
    messagingSenderId: '673136464233',
    appId: '1:673136464233:web:4452afe34384062f186df8',
  };

  // Initialize Firebase
  initializeApp(firebaseConfig);
};

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

initDatadog();
initFirebase();

interface Props {
  children: React.ReactNode;
}

const utmFields = [
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term',
  'utm_content',
  'gclid',
  'sspdata',
];

const Root = ({ children }: Props) => {
  const { t, locale } = useTranslation();
  dayjs.locale(locale);
  moment.locale(locale);

  const { isVisible, hideModal } = useModal(ModalEnum.ssoLogDifferentUser);
  const email = LocalStorage.getItem('userConnectedSsoEmail');
  const qs = typeof window !== 'undefined' && queryString.parse(window.location.search);

  if (qs && utmFields.some(utmField => qs[utmField])) {
    utmFields.forEach(utmField => {
      LocalStorage.removeItem(utmField);
      LocalStorage.setItem(utmField, `${qs[utmField] || ''}`);
    });
  }

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <div>{children}</div>
      <Modal
        title={t('ssoErrorTitle')}
        onRequestClose={() => {
          LocalStorage.removeItem('userConnectedSsoEmail');
          hideModal();
        }}
        isOpen={!!email && isVisible}
      >
        <div>{t('ssoErrorMessage', { email })}</div>
      </Modal>
    </ErrorBoundary>
  );
};

export default Root;
