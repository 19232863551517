/* eslint-disable react/jsx-filename-extension */
const React = require('react');
const Layout = require('./src/components/root').default;
const { TranslationProvider } = require('./src/hooks/use-translation');
const Providers = require('./src/utils/wrap-root-element').default;

exports.wrapRootElement = ({ element }) => <Providers>{element}</Providers>;

exports.wrapPageElement = ({ element, props }) => {
  const translations = props.data?.translations || props.pageContext.translations;

  return (
    <TranslationProvider value={{ translations, locale: props.pageContext.locale || 'fr' }}>
      <Layout {...props}>{element}</Layout>
    </TranslationProvider>
  );
};
