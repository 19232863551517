import {
  ApolloClient,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo-cache-persist';
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types';
import fetch from 'cross-fetch';
import { onLogout } from 'service/gtm';
import { getItem, removeItem, setItem } from 'utils/local-storage-helpers';
import { getToken } from 'utils/token';
import { REFRESH_TOKEN } from './mutations/user-mutations';

export const cache = new InMemoryCache();

const getNewToken = () => {
  const { token } = getToken();
  const refreshToken = getItem('refreshToken');

  return (
    ectorV4ClientWithoutAuth
      .mutate({
        mutation: REFRESH_TOKEN,
        variables: {
          token,
          refreshToken,
        },
      })
      // perform logout's actions if refresh call failed
      .catch(() => {
        removeItem('token');
        removeItem('refreshToken');
        onLogout();
        window.location.reload();
      })
      .then(response => {
        if (!response || !response.data) {
          return {};
        }

        const { refreshToken } = response.data;

        return refreshToken;
      })
  );
};

const rerunOperationAfterRefreshToken = (operation: Operation, forward: NextLink) => {
  const { token } = getToken();
  const refreshToken = getItem('refreshToken');

  if (!token || !refreshToken) {
    removeItem('token');
    removeItem('refreshToken');
    window.location.reload();

    return;
  }

  return fromPromise(getNewToken())
    .filter(value => Boolean(value))
    .flatMap(({ token, refreshToken }) => {
      if (!token || !refreshToken) {
        removeItem('token');
        removeItem('refreshToken');
        window.location.reload();
      }

      setItem('token', token);
      setItem('refreshToken', refreshToken);

      const oldHeaders = operation.getContext().headers;
      // modify the operation context with a new token
      operation.setContext({
        headers: {
          ...oldHeaders,
          authorization: `Bearer ${token}`,
        },
      });

      // retry the request, returning the new observable
      return forward(operation);
    });
};

if (typeof window !== `undefined`) {
  persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
  });
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions) {
        switch (extensions.code) {
          case 'UNAUTHENTICATED':
            return rerunOperationAfterRefreshToken(operation, forward);
          default:
            return undefined;
        }
      }
    });
  }

  if (networkError && 'statusCode' in networkError && networkError.statusCode) {
    switch (networkError.statusCode) {
      case 401:
        return rerunOperationAfterRefreshToken(operation, forward);
      default:
        return undefined;
    }
  }
});

const authLink = setContext(() => {
  const { token } = getToken();

  return {
    headers: {
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      'X-ECTOR-ORIGIN': 'front',
    },
  };
});

const link = from([
  errorLink,
  authLink,
  new HttpLink({
    uri: `${process.env.GATSBY_V3_API_URL}/graphql`,
    fetch,
  }),
]);

const ectorV4Link = from([
  errorLink,
  authLink,
  new HttpLink({
    uri: `${process.env.GATSBY_V4_API_URL}/graphql`,
    fetch,
  }),
]);

export const ectorV4Client = new ApolloClient({
  link: ectorV4Link,
  ssrMode: typeof window === `undefined`,
  cache,
});

export const ectorV4ClientWithoutAuth = new ApolloClient({
  link: from([
    new HttpLink({
      uri: `${process.env.GATSBY_V4_API_URL}/graphql`,
      fetch,
    }),
  ]),
  ssrMode: typeof window === `undefined`,
  cache,
});

export default new ApolloClient({
  link,
  ssrMode: typeof window === `undefined`,
  cache,
});
