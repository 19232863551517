import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import moment, { Moment } from 'moment';

dayjs.extend(duration);

export const numberOfDaysBetweenTwoDate = (startAt: string, endAt: string) =>
  Math.ceil(dayjs(endAt).diff(startAt, 'days', true));

export const getFormattedDelayTime = (delay: number): string => {
  return delay !== 0
    ? dayjs.duration((delay - new Date().getTime()) / 1000, 's').humanize(true)
    : '';
};

export const getWeekDayInitial = (date: dayjs.Dayjs) => {
  const weekDays = dayjs.localeData().weekdays();

  return weekDays[date.day()].substring(0, 1).toUpperCase();
};

export const getWeekDayInitialMoment = (date: Moment) => {
  const weekDays = moment.localeData().weekdays();

  return weekDays[date.day()].substring(0, 1).toUpperCase();
};
