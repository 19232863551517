import { DefaultTheme, ThemeColor, ThemeDimension, ThemeShadow } from 'styled-components';

type Operator = 'or' | 'and';

export enum FontSize {
  XS = 'small',
  S = 'normal',
  M = 'heading',
  L = 'subtitle',
  XL = 'title',
}

export enum FontWeight {
  Thin = 'thin',
  Light = 'light',
  Regular = 'regular',
  Bold = 'bold',
}

export enum Radius {
  S = 'small',
  M = 'medium',
  L = 'large',
}

export enum Space {
  XS = 'xSmall',
  S = 'small',
  M = 'medium',
  L = 'large',
  XL = 'xLarge',
}

const reduceOr = (props: { [key: string]: any }, values: Array<string>): boolean =>
  values.reduce((acc, val) => acc || props[val], props[values[0]]);

const reduceAnd = (props: { [key: string]: any }, values: Array<string>): boolean =>
  values.reduce((acc, val) => acc && props[val], props[values[0]]);

const validate = (props: object, value: string | Array<string>, operator: Operator): boolean => {
  const values = Array.isArray(value) ? value : [value];

  return operator === 'or' ? reduceOr(props, values) : reduceAnd(props, values);
};

export interface ThemePropsInterface {
  theme: DefaultTheme;
}

export const c =
  (color: ThemeColor) =>
  ({ theme }: ThemePropsInterface): string =>
    theme.colors[color];

export const d =
  (dimensionName: ThemeDimension) =>
  ({ theme }: ThemePropsInterface): string =>
    `${theme.dimensions[dimensionName]}px`;

export const ternaryC =
  (
    value: string | Array<string>,
    colorTrue: ThemeColor,
    colorFalse: ThemeColor,
    operator: Operator = 'and',
  ) =>
  ({ theme, ...rest }: ThemePropsInterface): string =>
    validate(rest, value, operator) ? theme.colors[colorTrue] : theme.colors[colorFalse];

export const s =
  (space: Space) =>
  ({ theme }: ThemePropsInterface): string =>
    `${theme.spaces[space]}px`;

export const ternaryS =
  (
    value: string | Array<string>,
    spaceTrue: Space,
    spaceFalse: Space,
    operator: Operator = 'and',
  ) =>
  ({ theme, ...rest }: ThemePropsInterface): string =>
    `${validate(rest, value, operator) ? theme.spaces[spaceTrue] : theme.spaces[spaceFalse]}px`;

export const fs =
  (fontSize: FontSize) =>
  ({ theme }: ThemePropsInterface): string =>
    `${theme.fontSizes[fontSize]}px`;

export const fw =
  (fontWeight: FontWeight) =>
  ({ theme }: ThemePropsInterface): number =>
    theme.fontWeights[fontWeight];

export const r =
  (radius: Radius) =>
  ({ theme }: ThemePropsInterface): string =>
    `${theme.radiuses[radius]}px`;

export const sh =
  (shadowName: ThemeShadow) =>
  ({ theme }: ThemePropsInterface): string =>
    theme.shadows[shadowName];
