import { useState } from 'react';
import {
  CheckEmailResponse,
  LoginResponse,
  RefreshResponse,
  SwapTokenResponse,
  UserType,
} from '../types/login-type';
import { getToken } from '../utils/token';

export type Method = 'GET' | 'POST' | 'DELETE' | 'PUT';

export type ApiError = {
  hint: string;
  code: string;
  error?: string;
  errors?: any[];
};

export type ApiResponse<T> = {
  ok: boolean;
  data?: T;
  error?: ApiError;
};

type SetIsFetchingType = (b: boolean) => void;
type SetResponseType<T> = (ar: ApiResponse<T> | null) => void;

const callApi = async <T>(
  route: string,
  method: Method,
  body: any,
  setIsFetching: SetIsFetchingType,
  setResponse: SetResponseType<T>,
  onComplete: (data: T) => void,
  onError: (error: ApiError) => void,
) => {
  setIsFetching(true);
  try {
    const bodyString = body ? JSON.stringify(body) : undefined;
    const { token } = getToken(); // Better to grab it from auth context but we might run into circular dependencies
    const response = await fetch(route, {
      method,
      ...(bodyString ? { body: bodyString } : {}),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-ector-origin': 'front',
      },
    });

    const responseBodyText = await response.text();
    const responseBody = responseBodyText ? JSON.parse(responseBodyText) : {};

    if (!response.ok) {
      setResponse({
        ok: false,
        error: {
          hint: responseBody.error ?? `Error: ${response.status}`,
          code: responseBody.code ?? `UNKNOWN_ERROR`,
          error: responseBody.error,
          errors: responseBody.errors,
        },
      });
      onError({
        hint: responseBody.error ?? `Error: ${response.status}`,
        code: responseBody.code ?? `UNKNOWN_ERROR`,
        error: responseBody.error,
        errors: responseBody.errors,
      });
    } else {
      setResponse({
        ok: true,
        data: responseBody,
      });
      onComplete(responseBody);
    }
  } catch (error) {
    setResponse({
      ok: false,
      error: {
        hint: (error as any)?.message ?? String(error),
        code: 'UNKNOWN_ERROR',
        error: (error as any)?.error,
      },
    });
    onError({
      hint: (error as any)?.message ?? String(error),
      code: 'UNKNOWN_ERROR',
      error: (error as any)?.error,
    });
  }
  setIsFetching(false);
};

type UrlParamsTuple<UrlParamsT> = UrlParamsT extends keyof any
  ? [{ [K in UrlParamsT]: string }]
  : [undefined?];

const buildApiRoute =
  <
    UrlParamsT extends keyof any | undefined = undefined,
    BodyParamsT extends object | undefined = undefined,
    ResponseT extends object = object,
  >(
    route: string,
    method: Method,
  ) =>
  async (
    bodyOrParams: BodyParamsT,
    setIsFetching: SetIsFetchingType,
    setResponse: SetResponseType<ResponseT>,
    onComplete: (data: any) => void,
    onError: (error: ApiError) => void,
    ...urlParams: UrlParamsTuple<UrlParamsT>
  ) => {
    const body = method === 'POST' || method === 'PUT' ? bodyOrParams : undefined;
    const params = (method === 'GET' || method === 'DELETE' ? bodyOrParams : urlParams[0]) as
      | Record<string, string>
      | undefined;

    const routeWithParams = params
      ? Object.keys(params).reduce((r, param, idx) => {
          if (!params[param]) return r;

          if (r.indexOf(`$${param}`) > -1) {
            return r.replace(`$${param}`, params[param]);
          }

          return `${r}${idx === 0 ? '' : '&'}${param}=${params[param]}`;
        }, `${route}?`)
      : route;

    return callApi(routeWithParams, method, body, setIsFetching, setResponse, onComplete, onError);
  };

const apiRoutes = {
  getMe: buildApiRoute<undefined, undefined, UserType>(
    `${process.env.GATSBY_ECTOR_API}/users/me`,
    'GET',
  ),
  login: buildApiRoute<
    undefined,
    { email: string; password: string; origin: 'front' },
    LoginResponse
  >(`${process.env.GATSBY_ECTOR_AUTH}/login`, 'POST'),
  refreshToken: buildApiRoute<
    undefined,
    { token: string; refreshToken: string; origin: 'front' },
    RefreshResponse
  >(`${process.env.GATSBY_ECTOR_AUTH}/refresh-token`, 'POST'),
  swapToken: buildApiRoute<undefined, { sso_token: string; origin: 'front' }, SwapTokenResponse>(
    `${process.env.GATSBY_ECTOR_AUTH}/swap-token`,
    'POST',
  ),
  checkEmail: buildApiRoute<undefined, { email: string }, CheckEmailResponse>(
    `${process.env.GATSBY_ECTOR_AUTH}/check-email`,
    'POST',
  ),
};

type GetRouteParams<T> = T extends (
  a: infer Body,
  b: any,
  c: any,
  e: any,
  f: any,
  d: infer RouteParams,
  ...args: any[]
) => any
  ? RouteParams extends object
    ? RouteParams
    : Body
  : undefined;

type GetBodyParams<T> = T extends (
  a: any,
  b: any,
  c: any,
  e: any,
  f: any,
  d?: undefined,
  ...args: any[]
) => any
  ? undefined
  : T extends (a: infer Body, ...args: any[]) => any
    ? Body extends object
      ? Body
      : undefined
    : undefined;

type GetApiResponseType<T> = T extends (
  a: any,
  b: any,
  d: (aa: infer ResponseType) => any,
  e: any,
  ...args: any[]
) => any
  ? ResponseType
  : boolean;

type GetResponseType<T> = T extends (
  a: any,
  b: any,
  d: (aa: infer P) => void,
  e: any,
  ...args: any[]
) => any
  ? P extends ApiResponse<infer U>
    ? U
    : never
  : boolean;

export const useLazyApi = <U extends keyof typeof apiRoutes>(
  route: U,
  onComplete: (data: GetResponseType<(typeof apiRoutes)[U]>) => void = () => {},
  onError: (error: ApiError) => void = () => {},
) => {
  type BodyParams = GetBodyParams<(typeof apiRoutes)[U]>;
  type RouteParams = GetRouteParams<(typeof apiRoutes)[U]>;
  type ApiResponseT = GetApiResponseType<(typeof apiRoutes)[U]>;
  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState<ApiResponseT | null>(null);
  const fetch = (
    ...args: BodyParams extends object
      ? [RouteParams, BodyParams]
      : RouteParams extends object
        ? [RouteParams]
        : []
  ) => {
    const routeParamsOrBody = args[0];
    const body = args[1];

    if (body) {
      return apiRoutes[route](
        body as never,
        setIsFetching,
        setResponse as SetResponseType<object>,
        onComplete,
        onError,
        routeParamsOrBody as never,
      );
    }

    return apiRoutes[route](
      routeParamsOrBody as never,
      setIsFetching,
      setResponse as SetResponseType<object>,
      onComplete,
      onError,
    );
  };

  return {
    fetch,
    isFetching,
    response,
  };
};
