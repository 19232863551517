import { css } from 'styled-components';
import { c, FontWeight, fw, r, Radius, s, Space } from 'utils/theme-helpers';

export const ReactDatesGlobal = css`
  .TimeRange__hours {
    padding: 15px 5px;
  }

  .CalendarDay {
    border: none;
  }

  .CalendarDay__default {
    padding: 0;
    color: ${c('primary')} !important;
    position: relative;
    user-select: none;
  }

  .CalendarDay__default:not(.CalendarDay__selected):not(.CalendarDay__blocked_calendar):hover {
    background: transparent !important;
    border: none;
    color: ${c('background')} !important;
    border-radius: ${r(Radius.L)};
    z-index: 3;
  }

  .CalendarDay__selected_start {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  .CalendarDay__selected_end {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .DayPicker {
    border-radius: ${s(Space.XS)};
  }

  .CalendarDay__hovered_span,
  .CalendarDay__selected_span {
    background: transparent !important;
    color: ${c('primary')} !important;
    border: none !important;
    z-index: 1;
  }

  .CalendarDay__hovered_span::before,
  .CalendarDay__selected_span::before {
    content: '';
    background-color: ${c('lightNeutral')};
    height: 24px;
    width: 100%;
    left: 0;
    position: absolute;
    z-index: -1;
  }

  .CalendarDay__default:not(.CalendarDay__selected):not(.CalendarDay__blocked_calendar):hover,
  .CalendarDay__selected {
    background: transparent !important;
    border: none !important;
    color: ${c('background')} !important;
    border-radius: ${r(Radius.M)};
    z-index: 1;
  }

  .CalendarDay__selected_start::before {
    content: '';
    background-color: ${c('lightNeutral')};
    height: 24px;
    width: 100%;
    left: 0;
    border-top-left-radius: ${r(Radius.M)};
    border-bottom-left-radius: ${r(Radius.M)};
    position: absolute;
    z-index: -1;
  }

  .CalendarDay__default:not(.CalendarDay__selected).CalendarDay__hovered_span:hover::before,
  .CalendarDay__selected_end::before {
    content: '';
    background-color: ${c('lightNeutral')};
    height: 24px;
    width: 100%;
    left: 0;
    border-top-right-radius: ${r(Radius.M)};
    border-bottom-right-radius: ${r(Radius.M)};
    position: absolute;
    z-index: -1;
  }

  .CalendarDay__default:not(.CalendarDay__selected):hover::after,
  .CalendarDay__selected::after {
    content: '';
    background-color: ${c('info')};
    box-sizing: border-box;
    height: 24px;
    width: 100%;
    border-radius: ${r(Radius.M)};
    left: 0;
    position: absolute;
    z-index: -1;
  }

  .CalendarDay__hovered_span {
    color: ${c('primary')} !important;
    background: transparent !important;
  }

  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background-color: transparent !important;
    border: transparent !important;
    color: ${c('darkNeutral')} !important;
  }

  .CalendarDay__selected_start,
  .CalendarDay__selected_end {
    font-weight: ${fw(FontWeight.Bold)};
  }

  .CalendarMonth_caption {
    color: ${c('info')} !important;
    padding-top: 0;
    padding-bottom: ${s(Space.L)};
  }

  .DayPicker_weekHeader {
    top: ${s(Space.L)};
  }

  .DayPicker_weekHeader_ul {
    margin: 0;
  }

  .DayPicker_weekHeader_li {
    height: ${s(Space.L)};
    line-height: ${s(Space.L)};
  }

  .DayPicker_weekHeader_li small {
    color: ${c('info')} !important;
  }

  .DayPickerNavigation_button__default,
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    outline: none;
    border: none;
  }

  .DayPickerNavigation_button__horizontalDefault {
    top: 0;
    padding: ${s(Space.XS)};
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: ${s(Space.M)};
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: ${s(Space.M)};
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${c('primary')};
  }

  .CalendarMonthGrid_month__horizontal:nth-child(2):not(:nth-last-child(2)) {
    border-right: 1px solid ${c('neutral')};
  }

  .CalendarMonthGrid {
    height: 100%;
  }

  .CalendarMonth {
    display: flex;
    flex-direction: column;
  }

  .CalendarMonth_verticalSpacing {
    display: flex;
    flex: 1;
  }

  @media (max-width: 760px) {
    .DayPicker_weekHeader {
      display: none;
    }

    .CalendarMonth_caption {
      padding-top: ${s(Space.XS)};
      padding-bottom: ${s(Space.XS)};
    }

    [origin='edit'][type='start'] .CalendarDay__selected_start,
    [origin='edit'][type='end'] .CalendarDay__selected_end {
      color: ${c('primary')} !important;
    }

    [origin='edit'][type='start'] .CalendarDay__selected_start::after,
    [origin='edit'][type='end'] .CalendarDay__selected_end::after {
      background-color: ${c('background')};
      border: 2px solid ${c('lightAccent')};
    }

    .CalendarDay__blocked_calendar::after {
      display: none;
    }

    [type='start'] .CalendarDay__selected_start::before {
      display: none;
    }
  }
`;
