import { datadogRum } from '@datadog/browser-rum';
import { ApiBookingT } from 'types/booking-type';
import { numberOfDaysBetweenTwoDate } from 'utils/date-helper';
import { setItem } from 'utils/local-storage-helpers';
import { Steps } from '../container/PricingJockeySection/Default/pricing-jockey-section-types';

export interface GTMImpressionType {
  name: string;
  price: string | number;
  list: string;
  id?: string;
  category?: string;
  variant?: string;
}

export interface GTMProductType {
  name: string;
  id?: string;
  price: string | number;
  category?: string;
  variant?: string;
  list?: string;
}

type BookingAmplitude = {
  startAt?: string | null;
  endAt?: string | null;
  fromZoneCode?: string | null;
  toZoneCode?: string | null;
  reason?: string | null;
  duration?: number;
  summaryPrices?: {
    totalPrice: string | number;
  } | null;
};

const getBookingProducts = (booking: ApiBookingT) => {
  const products = [];

  products.push({
    id: booking.id || '',
    price: booking.summaryPrices ? booking.summaryPrices.totalPrice : '',
    name: booking.fromSpot ? booking.fromSpot.zone.code.toUpperCase() : '',
    category: numberOfDaysBetweenTwoDate(booking.startAt || '', booking.endAt || '').toString(),
    list: booking.fromSpot ? booking.fromSpot.type : '',
    quantity: 1,
    variant: booking.disallowedReason || 'allowed',
  });

  if (booking.subscribedServices) {
    booking.subscribedServices.forEach(serviceCode => {
      const foundService = booking.availableServices.find(service => service.code === serviceCode);

      if (foundService) {
        products.push({
          id: foundService.code,
          name: foundService.name,
          price: foundService.price,
          category: foundService.category,
          list: 'Service',
          quantity: 1,
        });
      }
    });
  }

  return products;
};

const getAmplitudeData = (booking: ApiBookingT) => {
  const { summaryPrices, isBusiness, startAt, endAt, fromZone, toZone } = booking;

  return {
    summaryPrices,
    duration: numberOfDaysBetweenTwoDate(startAt || '', endAt || ''),
    reason: isBusiness ? 'work' : 'leisure',
    fromZoneCode: fromZone ? fromZone.code : null,
    toZoneCode: toZone ? toZone.code : null,
    startAt,
    endAt,
  };
};

/** ** Checkout *** */
const onCheckout = (
  step: number,
  name: string,
  option: string | boolean | null = null,
  products: Array<GTMProductType> = [],
  amplitude: BookingAmplitude = {},
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'checkout',
      eventCategory: 'Ecommerce',
      eventAction: 'Funnel Step',
      eventLabel: name,
      amplitude,
      ecommerce: {
        checkout: {
          actionField: {
            step,
            name,
            option,
          },
          products,
        },
      },
    });
  }
};

export const onCheckoutStartPricingForm = () => {
  onCheckout(1, 'Start Pricing Form');
};

export const onCheckoutPriceDisplay = (booking: ApiBookingT) => {
  const products = getBookingProducts(booking);
  const amplitude = getAmplitudeData(booking);

  onCheckout(
    2,
    'Price Display',
    !booking.allowed ? booking.disallowedReason : 'allowed',
    products,
    amplitude,
  );
};

export const onCheckoutPopup = (booking: ApiBookingT) => {
  const products = getBookingProducts(booking);
  const amplitude = getAmplitudeData(booking);

  onCheckout(3, 'Popup Display', 'not-displayed', products, amplitude);
};

export const onUpdateCheckoutPopup = (booking: ApiBookingT) => {
  const singleOrMultipleAlternateDates =
    booking.alternatesStartDate.length === 2 ? 'multiple' : 'single';

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 3,
            option: booking.allowed ? 'not-displayed' : singleOrMultipleAlternateDates,
          },
        },
      },
    });
  }
};

export const onCheckoutOptionsDisplay = (booking: ApiBookingT) => {
  const products = getBookingProducts(booking);

  onCheckout(4, 'Options Display', null, products);
};

export const addProduct = (
  eventAction: string,
  eventLabel: string,
  eventValue: string | number,
  product: GTMProductType,
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'addProduct',
      eventCategory: 'Ecommerce',
      eventAction,
      eventLabel,
      eventValue,
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [product],
        },
      },
    });
  }
};

export const removeProduct = (
  eventAction: string,
  eventLabel: string,
  eventValue: string,
  product: GTMProductType,
) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'removeProduct',
      eventCategory: 'Ecommerce',
      eventAction,
      eventLabel,
      eventValue,
      ecommerce: {
        currencyCode: 'EUR',
        remove: {
          products: [product],
        },
      },
    });
  }
};

export const addBookingToCart = (booking: ApiBookingT) => {
  addProduct(
    'Add Booking',
    booking.fromSpot ? booking.fromSpot.zone.code.toUpperCase() : '',
    booking.summaryPrices.totalPrice,
    {
      id: booking.id,
      name: booking.fromSpot ? booking.fromSpot.zone.code.toUpperCase() : '',
      price: booking.summaryPrices.totalPrice,
      variant: booking.disallowedReason || 'allowed',
      category: numberOfDaysBetweenTwoDate(booking.startAt, booking.endAt).toString(),
      list: booking.fromSpot ? booking.fromSpot.type : '',
    },
  );
};

/** ** Impression *** */
const addImpression = (eventAction: string, impressions: Array<GTMImpressionType>) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'addImpression',
      eventCategory: 'Ecommerce',
      eventAction,
      ecommerce: {
        currencyCode: 'EUR',
        impressions,
      },
    });
  }
};

export const onDisplayBooking = (booking: ApiBookingT) => {
  addImpression('Display Booking', [
    {
      id: booking.id,
      price: booking.summaryPrices ? booking.summaryPrices.totalPrice : '',
      name: booking.fromSpot ? booking.fromSpot.zone.code.toUpperCase() : '',
      category: numberOfDaysBetweenTwoDate(booking.startAt, booking.endAt).toString(),
      list: booking.fromSpot ? booking.fromSpot.type : '',
      variant: booking.disallowedReason || 'allowed',
    },
  ]);
};

export const onLogin = (user: { email: string }) => {
  setItem('email', user.email);

  if (window?.dataLayer) {
    const lastEvent = window.dataLayer[window.dataLayer.length - 1];

    if (lastEvent && lastEvent.event !== 'login') {
      window.dataLayer.push({
        event: 'login',
        user,
      });
    }
  }
};

export const onValidateFleetStep = (
  step: Steps | 'FleetPayment' | 'FleetPaymentError' | 'FleetPaymentSuccess',
  amplitude: object = {},
) => {
  datadogRum.addAction(`fleetStep-${step}`, {
    category: 'Fleet',
    action: 'Fleet Step',
    label: `Step ${step}`,
    amplitude,
  });

  if (window?.dataLayer) {
    window.dataLayer.push({
      event: `fleetStep-${step}`,
      eventCategory: 'Fleet',
      eventAction: 'Fleet Step',
      eventLabel: `Step ${step}`,
      amplitude,
    });
  }
};

export const onLogout = (askedFor = false) => {
  if (window?.dataLayer) {
    window.dataLayer.push({
      event: 'logout',
      amplitude: {
        askedFor,
      },
    });
  }
};
