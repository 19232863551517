import React, { createContext, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { CREATE_QUOTE } from 'apollo/mutations/booking';
import { EctorErrorType, useMutation } from 'hooks/graphql-hooks';
import { ApiBookingT } from 'types/booking-type';
import { getItem, removeItem, setItem } from 'utils/local-storage-helpers';

const BookingContext = createContext<any>({});

export const BookingProvider = ({ children }: { children: React.ReactNode }) => {
  const memoizedBooking = useMemo<ApiBookingT | null>(() => {
    try {
      const bookingString = getItem('booking');

      return bookingString ? JSON.parse(bookingString) : null;
      // eslint-disable-next-line no-empty
    } catch (e) {
      removeItem('booking');

      return null;
    }
  }, []);

  const [booking, setBookingState] = useState(memoizedBooking);

  const setBooking = (b: ApiBookingT) => {
    setItem('booking', JSON.stringify(b));
    setBookingState(b);
  };

  return (
    <BookingContext.Provider value={{ booking, setBooking }}>{children}</BookingContext.Provider>
  );
};

type UseBookingT = {
  onCreateQuoteCompleted?: (createQuote: ApiBookingT) => void;
  onCreateQuoteError?: (errors: EctorErrorType[]) => void;
};

/**
 * Return login tools
 */
const useBooking = (
  { onCreateQuoteCompleted, onCreateQuoteError }: UseBookingT = {
    onCreateQuoteCompleted: () => {},
    onCreateQuoteError: () => {},
  },
) => {
  const { booking, setBooking } = useContext(BookingContext);

  const [createQuote, { loading: createQuoteLoading }] = useMutation(
    CREATE_QUOTE,
    {
      onCompleted: data => {
        if (data) {
          setBooking({
            ...data.createQuote,
            expiredAt: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
          });
        }

        if (onCreateQuoteCompleted) {
          onCreateQuoteCompleted(data?.createQuote);
        }
      },
    },
    onCreateQuoteError,
  );

  return {
    booking,
    setBooking,
    createQuote,
    createQuoteLoading,
  };
};

export default useBooking;
