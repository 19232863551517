import { DefaultTheme } from 'styled-components';
import defaultTheme from './default-theme.json';

const theme: DefaultTheme = {
  colors: {
    primary: defaultTheme.colorPrimary.value.hex,
    lightPrimary: defaultTheme.colorLightPrimary.value.hex,
    neutral: defaultTheme.colorNeutral.value.hex,
    lightNeutral: defaultTheme.colorLightNeutral.value.hex,
    darkNeutral: defaultTheme.colorDarkNeutral.value.hex,
    background: defaultTheme.colorBackground.value.hex,
    accent: defaultTheme.colorAccent.value.hex,
    lightAccent: defaultTheme.colorLightAccent.value.hex,
    info: defaultTheme.colorInfo.value.hex,
    action: defaultTheme.colorAction.value.hex,
    success: defaultTheme.colorSuccess.value.hex,
    error: defaultTheme.colorError.value.hex,
    bronze: defaultTheme.colorBronze.value.hex,
    silver: defaultTheme.colorSilver.value.hex,
    gold: defaultTheme.colorGold.value.hex,
    platinum: defaultTheme.colorPlatinum.value.hex,
  },
  dimensions: {
    iconHeight: 20,
    headerHeight: 60,
    containerWidth: 1232,
    pricingModalHeaderHeight: 90,
    pricingSectionHeight: 630,
  },
  fontSizes: {
    small: defaultTheme.fontSizeSmall.value,
    normal: defaultTheme.fontSizeNormal.value,
    heading: defaultTheme.fontSizeHeading.value,
    subtitle: defaultTheme.fontSizeSubtitle.value,
    title: defaultTheme.fontSizeTitle.value,
  },
  fontWeights: {
    thin: defaultTheme.fontWeightThin.value,
    light: defaultTheme.fontWeightLight.value,
    regular: defaultTheme.fontWeightRegular.value,
    bold: defaultTheme.fontWeightBold.value,
  },
  radiuses: {
    small: defaultTheme.radiusSmall.value,
    medium: defaultTheme.radiusMedium.value,
    large: defaultTheme.radiusLarge.value,
  },
  shadows: {
    pickerBox: '0 4px 8px 2px rgba(0, 0, 0, 0.25)',
    article: '0px 4px 23px rgba(0, 0, 0, 0.15)',
    pricingResult: '-5px 2px 15px 0 rgba(0, 0, 0, 0.06)',
    faqBox: '0px 4px 19px rgba(0, 0, 0, 0.05)',
    card: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    whiteText: '0px 2px 2px rgba(0, 0, 0, 0.75)',
  },
  spaces: {
    xSmall: defaultTheme.spaceXsmall.value,
    small: defaultTheme.spaceSmall.value,
    medium: defaultTheme.spaceMedium.value,
    large: defaultTheme.spaceLarge.value,
    xLarge: defaultTheme.spaceXlarge.value,
  },
};

export default theme;
