import React, { createContext, useContext, useReducer } from 'react';

enum AlertAction {
  SET_VISIBLE_ALERT = 'SET_VISIBLE_ALERT',
}

interface AlertT {
  title?: string;
  message?: string;
  type: AlertTypeEnum;
  visible?: boolean;
}
interface AlertStateT {
  [key: string]: AlertT;
}

interface AlertActionT {
  type: string;
  alert: {
    type: string;
    data: AlertT;
  };
}

export enum AlertsEnum {
  resetPasswordSuccess = 'resetPasswordSuccess',
  impossibleBookingSuccess = 'impossibleBookingSuccess',
  updatePasswordSuccess = 'updatePasswordSuccess',
  businessFormSuccess = 'businessFormSuccess',
  jockeyFormSuccess = 'jockeyFormSuccess',
}

export enum AlertTypeEnum {
  error = 'error',
  success = 'success',
  info = 'info',
}

const AlertContext = createContext<[any, any]>([null, null]);

const AlertReducer = (alerts: AlertStateT, action: AlertActionT) => {
  switch (action.type) {
    case AlertAction.SET_VISIBLE_ALERT:
      return {
        ...alerts,
        [action.alert.type]: {
          ...alerts[action.alert.type],
          ...action.alert.data,
        },
      };

    default:
      return { ...alerts };
  }
};

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const reducer = useReducer<any>(AlertReducer, {});

  return <AlertContext.Provider value={reducer}>{children}</AlertContext.Provider>;
};

const useAlert = (alertName: AlertsEnum, variables?: AlertT) => {
  const [state, dispatch] = useContext(AlertContext);
  const defaultValues = { title: null, message: null, type: 'error' };

  const alert = state?.[alertName] ? state[alertName] : {};

  return {
    isVisible: !!alert.visible,
    data: alert,
    showAlert: () =>
      dispatch({
        type: AlertAction.SET_VISIBLE_ALERT,
        alert: {
          type: alertName,
          data: {
            ...defaultValues,
            ...variables,
            visible: true,
          },
        },
      }),
    hideAlert: () =>
      dispatch({
        type: AlertAction.SET_VISIBLE_ALERT,
        alert: {
          type: alertName,
          data: {
            ...defaultValues,
            ...variables,
            visible: false,
          },
        },
      }),
  };
};

export default useAlert;
