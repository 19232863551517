import React from 'react';
import { datadogRum } from '@datadog/browser-rum';

type ErrorBoundaryPropsType = {
  children: any;
  fallback?: React.ReactNode;
};

type ErrorBoundaryStateType = {
  hasError: boolean;
};

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

class ErrorBoundary extends React.Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if (typeof window !== `undefined`) {
      datadogRum.addError(error);
    }
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback || <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
