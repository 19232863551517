import { datadogRum } from '@datadog/browser-rum';
import dayjs from 'dayjs';
import JwtDecode from 'jwt-decode';
import { onLogout } from 'service/gtm';
import { getItem, removeItem } from 'utils/local-storage-helpers';

type DecodedTokenT = { roles: string[]; username: string; iat: number; exp: number; sub: string };

export const getToken = (): {
  token?: string;
  decodedToken?: DecodedTokenT;
} => {
  const token = getItem('token') || '';

  if (token) {
    try {
      const decodedToken: DecodedTokenT = JwtDecode(token);
      const now = dayjs();

      if (
        !decodedToken.sub ||
        now.diff(dayjs.unix(decodedToken.iat), 'month') >= 6 ||
        now.isAfter(dayjs.unix(decodedToken.exp))
      ) {
        throw new Error('Invalid token');
      }

      return { token, decodedToken };
    } catch (e) {
      removeItem('token');
      onLogout();
      datadogRum.addError(e);
    }
  }

  return {};
};
