import gql from 'graphql-tag';

export const GET_TOKEN = gql`
  query getToken($refreshToken: String) {
    getToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const GET_ME = gql`
  query getMe {
    me {
      firstName
      lastName
      email
      mainContact {
        id
        phone
        clientProfile {
          hasPaidBooking
          businessPartner {
            name
          }
        }
      }
    }
  }
`;
