import { css } from 'styled-components';
import { c, FontWeight, fw, r, Radius, s, Space } from 'utils/theme-helpers';

export const CookiesDialogGlobal = css`
  #CybotCookiebotDialog {
    background: ${c('background')};
    border-radius: ${r(Radius.L)};
    box-shadow: 2px 2px ${s(Space.S)} 1px rgba(0, 0, 0, 0.2);
    color: ${c('primary')};
    font-family: 'GT-Walsheim';
    font-size: ${s(Space.S)};
    line-height: ${s(Space.M)};
    max-height: 80vh;
    max-width: 610px;
    overflow: hidden;
    position: fixed;
    padding: ${s(Space.L)} ${s(Space.S)};
    text-rendering: geometricPrecision;
    width: auto;
    z-index: 1000;
  }

  #CybotCookiebotDialog a {
    color: ${c('primary')};
  }

  #CybotCookiebotDialog a:focus {
    outline: none;
  }

  #CybotCookiebotDialogBody {
    height: 100%;
    overflow: auto;
    padding: 0 ${s(Space.S)};
  }

  #CybotCookiebotDialogBodyContent {
    margin-bottom: ${s(Space.M)};
  }

  #CybotCookiebotDialogBodyContentTitle {
    font-size: ${s(Space.M)};
    font-weight: ${fw(FontWeight.Bold)};
    line-height: 44px;
    margin: 0;
  }

  #CybotCookiebotDialogBodyContentText {
    margin-top: ${s(Space.M)};
  }

  #CybotCookiebotDialogDetailBodyContentCookieContainerAdvertising,
  #CybotCookiebotDialogDetailBodyContentCookieContainerNecessary,
  #CybotCookiebotDialogDetailBodyContentCookieContainerPreference,
  #CybotCookiebotDialogDetailBodyContentCookieContainerStatistics,
  #CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified {
    margin: 0;
  }

  #CybotCookiebotDialogBodyLevelWrapper {
    margin-bottom: ${s(Space.S)};
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    pointer-events: auto;
  }

  .CybotCookiebotDialogBodyButton {
    width: 375px;
    padding: 12px 15px;
    font-weight: ${fw(FontWeight.Bold)};
    margin-top: ${s(Space.XS)};
    margin-bottom: ${s(Space.XS)};
    border: 1px solid #000000;
    border-radius: 174px;
    text-align: center;
  }

  #CybotCookiebotDialogBodyLevelDetailsWrapper {
    display: table-cell;
    background-color: #f6f6f9;
    border: 1px solid #cccccc;
    border-left: none;
    cursor: pointer;
  }

  #CybotCookiebotDialogBodyLevelDetailsButton {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAGCAMAAAAmGUT3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkI3NDEyNDEwNzk0MjExRTQ5RUE5RkRFMUQ3MEU1NTZDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkI3NDEyNDExNzk0MjExRTQ5RUE5RkRFMUQ3MEU1NTZDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Qjc0MTI0MEU3OTQyMTFFNDlFQTlGREUxRDcwRTU1NkMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Qjc0MTI0MEY3OTQyMTFFNDlFQTlGREUxRDcwRTU1NkMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz70ohqeAAAABlBMVEVgYGAAAAAPhzbbAAAAAnRSTlP/AOW3MEoAAAAjSURBVHjaYmBkYAQBBkYGIAAxQBQcQ/ggCiLFCGFBCIAAAwADkwAg7Yr51AAAAABJRU5ErkJggg==);
    background-position: right;
    background-repeat: no-repeat;
    padding: 8px 16px;
    margin-right: 8px;
  }

  #CybotCookiebotDialogBodyLevelDetailsButton:focus {
    text-decoration: underline;
  }

  .CybotCookiebotDialogBodyLinkExpanded {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAGCAYAAAARx7TFAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjU0QzAwODExNzk0MjExRTQ4QzBERTBGMTkxMUY2M0M0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjU0QzAwODEyNzk0MjExRTQ4QzBERTBGMTkxMUY2M0M0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NTRDMDA4MEY3OTQyMTFFNDhDMERFMEYxOTExRjYzQzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NTRDMDA4MTA3OTQyMTFFNDhDMERFMEYxOTExRjYzQzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz576KdnAAAATklEQVR42kyO2xEAMQgCJZ3afxFcyGRz+uMD3EHdXbYrJSltrz4Dt4UBNfsWPG614oRwO2Q/Eg+IwvnDj8kjk+48MzmZeNYI/4jRPwEGAFy/MS7NcXxJAAAAAElFTkSuQmCC) !important;
  }

  #CybotCookiebotDialogBodyLevelButtons {
    font-size: 12px;
    margin-top: ${s(Space.S)};
    display: flex;
    justify-content: center;
  }

  #CybotCookiebotDialogBodyLevelButtonsRow {
    display: table-row;
  }

  #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border: 1px solid #cccccc;
    padding: 4px ${s(Space.XS)};
    border-radius: 4px 0 0 4px;
    text-align: left;
  }

  .CybotCookiebotDialogBodyLevelButtonWrapper {
    display: inline-block;
    position: relative;
    margin-right: ${s(Space.XS)};
    margin-left: ${s(Space.XS)};
    line-height: ${s(Space.S)};
  }

  .CybotCookiebotDialogBodyLevelButtonWrapper label {
    white-space: nowrap;
  }

  .CybotCookiebotDialogBodyLevelButton {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  .CybotCookiebotDialogBodyLevelButton + label:after,
  .CybotCookiebotDialogBodyLevelButton + label:before {
    content: normal;
  }

  .CybotCookiebotDialogBodyLevelButton.CybotCookiebotDialogBodyLevelButtonDisabled {
    cursor: default;
  }

  .CybotCookiebotDialogBodyLevelButton + label {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjYxNkU3NEJGQkJDMjExRTNCMzA3ODU5MUUzMDlDM0FDIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjYxNkU3NEMwQkJDMjExRTNCMzA3ODU5MUUzMDlDM0FDIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NjE2RTc0QkRCQkMyMTFFM0IzMDc4NTkxRTMwOUMzQUMiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NjE2RTc0QkVCQkMyMTFFM0IzMDc4NTkxRTMwOUMzQUMiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz71Yc/eAAAAOklEQVR42mL8//8/AzmAkf4aL1y4QLROfX19RjgHpBFkKyGMro6JgUwwqnFQaWQBERcvXiQ53QEEGADSSDs5lXMYKAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    height: auto;
    min-height: 14px;
    width: auto;
    display: inline-block;
    padding: 1px 0 0 17px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 0;
    background-position: left 1px;
    vertical-align: top;
    line-height: ${s(Space.S)};
    font-size: inherit;
    color: inherit;
  }

  .CybotCookiebotDialogBodyLevelButton:checked + label {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjNGMUE0MkE1QkJDMjExRTM5QUIxQzQwRjkwREYzMUIyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjNGMUE0MkE2QkJDMjExRTM5QUIxQzQwRjkwREYzMUIyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0YxQTQyQTNCQkMyMTFFMzlBQjFDNDBGOTBERjMxQjIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0YxQTQyQTRCQkMyMTFFMzlBQjFDNDBGOTBERjMxQjIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4IZcVrAAAA5UlEQVR42mL8//8/AyMjIwMxQHwRgwOQAuEJjMRqBGoSAFL3gRhEf2BiIB7Mh2oCgQ+MFy5c+E9Ix/o3SxlmPOtGFnJkAZEGBgaMeJyoAKTOI9k24WUcwwEmNEUGBJx4AaipEMRgQtIEUnAeSCcgiRVAQxEGEmEMsB/dLxk4Atn70RRcgDoRDGLEMxh63WfAvQSz8QIUIztvPRL/QixQIzIAawS6+wMopNA0K8CCHogD0T0O9yMOzSDQCJR7gFMjmuYFUKEDQLEJ2KKJBV0AqjkRGKILsdiOqhFv6kHSCkrXMAAQYACIkU0SIPgtxAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    min-height: 14px;
    display: inline-block;
    padding: 1px 0 0 17px;
    background-position: left 1px;
    vertical-align: top;
    line-height: ${s(Space.S)};
  }

  .CybotCookiebotDialogBodyLevelButton.CybotCookiebotDialogBodyLevelButtonDisabled + label {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjgzMjc3NEM2QkJDMjExRTNBN0ExOUJFMzFCMzdBRjdEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjgzMjc3NEM3QkJDMjExRTNBN0ExOUJFMzFCMzdBRjdEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODMyNzc0QzRCQkMyMTFFM0E3QTE5QkUzMUIzN0FGN0QiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODMyNzc0QzVCQkMyMTFFM0E3QTE5QkUzMUIzN0FGN0QiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz54CbH2AAABFElEQVR42oxSLQ/CQAy9I6hZMrVgQW4aCdnvGIoER8Lm+AcbWBIcFo0cFuw0dkxMMAlyvC69y/hek6VN19e+16ssy1JIKUUTC8Owh9o+wkNbNDSADICmCA18w1ZTIEAeg8juMkmS8h+oKAqR57nIsmzD0pYVVcdx5A+KHRQuLMuaEYj0+b5/br0UdT9QHANgcJwGQbCjWAOjKPKoM/yglhvB9dTW0WCrG5LGOI77SM5rz0IFKXILniRs2564rqsL2tzpQjQQKqoectdao9Q0zScJFVXwvsGteIr612F/Q279ql1rxKYqME8WNV17NL5+BSowCmnykUFngA6fnunt5Jj2Fhs9wV++XlKTy1GGzeptPQQYAF1/e0nsKZ1HAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    min-height: 14px;
    display: inline-block;
    padding: 1px 0 0 17px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: default;
    background-position: left 1px;
    vertical-align: top;
    line-height: ${s(Space.S)};
  }

  .CybotCookiebotDialogBodyLevelButton:focus + label {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkUwQkVDMzlCQkQ4NTExRTM5RTEwRUIwNUNENTg2N0Q4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkUwQkVDMzlDQkQ4NTExRTM5RTEwRUIwNUNENTg2N0Q4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTBCRUMzOTlCRDg1MTFFMzlFMTBFQjA1Q0Q1ODY3RDgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTBCRUMzOUFCRDg1MTFFMzlFMTBFQjA1Q0Q1ODY3RDgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7Y0XwIAAAAOElEQVR42mL8//8/AzmAiYFMQLZGxtLSUqLd2tXVxQhjs8AMIELf/4H146hGWmhkwRa5xACAAAMAL2gJGKxaSssAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    min-height: 14px;
    display: inline-block;
    padding: 1px 0 0 17px;
    background-position: left 1px;
    vertical-align: top;
    line-height: ${s(Space.S)};
  }

  .CybotCookiebotDialogBodyLevelButton:checked:focus + label {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjk3MjVBRTM5QkQ4MDExRTM4RDBEOTEzMTQxN0RDRjc0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjk3MjVBRTNBQkQ4MDExRTM4RDBEOTEzMTQxN0RDRjc0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTcyNUFFMzdCRDgwMTFFMzhEMEQ5MTMxNDE3RENGNzQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTcyNUFFMzhCRDgwMTFFMzhEMEQ5MTMxNDE3RENGNzQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7V1txIAAAA6UlEQVR42mL8//8/AyMjIwMxQHwRgwOQAuEJjMRqBGoSAFL3gRhEf2BiIB7Mh2oCgQ+MpaWl/wnpuC5yluG05D5kIUcWENnd3c2Ix4kKQOo8km0TXsYxHGBCU2RAwIkXgJoKQQwmJE0gBeeBdAKSWAE0FGEgEcZgQQpmmIb5QD7YdCDuhynUf2XFsKvk2AUYH2bjBShGdt56JP4F/ZfWKO4HawS6+wMopNA0K8CCHogD0T0O9yMOzSDQCJR7gFMjmuYFUKEDQLEJ2KKJBV0AqjkRGEALsdiOqhFr6rkMpUsRQqB0DQMAAQYAX31KK0vr8I0AAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    min-height: 14px;
    display: inline-block;
    padding: 1px 0 0 17px;
    background-position: left 1px;
    vertical-align: top;
    line-height: ${s(Space.S)};
  }

  #CybotCookiebotDialogDetail {
    display: none;
    padding: ${s(Space.XS)};
  }

  #CybotCookiebotDialogDetailBodyContent {
    height: 205px;
    border: 1px solid #cccccc;
    overflow: hidden;
  }

  #CybotCookiebotDialogDetailBodyContent a {
    font-size: 9pt;
  }

  #CybotCookiebotDialogDetailBodyContent a:last-child {
    border-bottom: none;
  }

  #CybotCookiebotDialogDetailBodyContentTabs {
    position: relative;
    height: auto;
    display: inline-block;
    white-space: nowrap;
  }

  .CybotCookiebotDialogDetailBodyContentTab {
    border-left: none;
  }

  .CybotCookiebotDialogDetailBodyContentTab:first-child {
    border-left: 1px solid #cccccc;
    border-radius: 4px 0 0;
  }

  .CybotCookiebotDialogDetailBodyContentTabsItem,
  .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
    border-right: 1px solid #cccccc;
    position: relative;
    top: 1px;
    z-index: 10;
    white-space: normal;
    line-height: 100%;
    display: inline-block;
    border-top: 1px solid #cccccc;
  }

  .CybotCookiebotDialogDetailBodyContentTabsItem {
    background: #f6f6f9;
    color: #2a2a2a !important;
    cursor: pointer;
    font-size: ${s(Space.S)};
    font-weight: 400;
    margin-top: 1px;
    opacity: 0.85;
    padding: 7px 18px 5px;
    text-decoration: none !important;
  }

  .CybotCookiebotDialogDetailBodyContentTabsItem:first-of-type {
    border-left: 1px solid #cccccc;
  }

  .CybotCookiebotDialogDetailBodyContentTabsItem:focus,
  .CybotCookiebotDialogDetailBodyContentTabsItem:hover {
    text-decoration: none !important;
    color: #2a2a2a !important;
    opacity: 0.9;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
  }

  .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
    background: ${c('background')};
    color: #2a2a2a !important;
    font-size: 10.5pt;
    font-weight: 400;
    margin: 0;
    opacity: 1;
    padding: ${s(Space.XS)} 18px 6px;
    text-decoration: none !important;
  }

  .CybotCookiebotDialogDetailBodyContentTabsItemSelected:focus,
  .CybotCookiebotDialogDetailBodyContentTabsItemSelected:hover {
    text-decoration: none !important;
    color: #2a2a2a !important;
    cursor: default;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
  }

  #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    float: left;
    white-space: nowrap;
    padding: 0;
    background-color: #f6f6f9;
    font-size: 9pt;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    padding: ${s(Space.XS)};
    cursor: pointer;
    background-color: #f6f6f9;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-left: none;
    display: block;
    text-decoration: none !important;
    color: #2a2a2a !important;
    opacity: 1;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes:first-child {
    border-top: 1px solid #cccccc;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes:focus,
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes:hover {
    text-decoration: none !important;
    background: ${c('background')} !important;
    color: #2a2a2a !important;
    opacity: 1;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-left: none;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypes label,
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected label {
    cursor: pointer;
    display: none;
    margin: 0;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected {
    padding: ${s(Space.XS)};
    background-color: ${c('background')};
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid ${c('background')};
    border-left: none;
    display: block;
    text-decoration: none !important;
    color: #2a2a2a !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:first-child {
    border-top: 1px solid ${c('background')};
  }

  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:focus,
  .CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected:hover {
    text-decoration: none !important;
    color: #2a2a2a !important;
    cursor: default;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid ${c('background')};
    border-left: none;
  }

  #CybotCookiebotDialogDetailBodyContentCookieContainerTypeDetails {
    padding: ${s(Space.S)};
    font-size: 12px;
    overflow: auto;
    height: 200px;
  }

  #CybotCookiebotDialogDetailBodyContentCookieTabsAdvertising,
  #CybotCookiebotDialogDetailBodyContentCookieTabsPreference,
  #CybotCookiebotDialogDetailBodyContentCookieTabsStatistics,
  #CybotCookiebotDialogDetailBodyContentCookieTabsUnclassified {
    display: none;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable {
    padding: 0;
    margin: ${s(Space.XS)} 0 0;
    font-size: 9pt;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead td,
  .CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th {
    background-color: #f6f6f9 !important;
    color: #2a2a2a !important;
    text-align: left;
    vertical-align: top;
    padding: 2px;
    border-bottom: 1px solid #cccccc;
    font-weight: 400;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td {
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    text-align: left;
    vertical-align: top;
    padding: 4px;
    max-width: 72px;
    overflow: hidden;
    font-size: 9pt;
    color: #2a2a2a !important;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td:last-child {
    border-right: 0;
  }

  .CybotCookiebotDialogDetailBodyContentCookieTypeTable
    tbody
    td.CybotCookiebotDialogDetailBodyContentCookieTypeTableEmpty {
    border: none;
    border-top: 1px solid #cccccc;
    padding: 4px 0 0;
  }

  #CybotCookiebotDialogDetailBodyContentTextAbout {
    padding: 18px 12px 12px;
    font-size: 9pt;
    height: 140px;
    overflow: auto;
    display: none;
  }

  #CybotCookiebotDialogDetailFooter {
    font-size: 12px;
    text-align: right;
    opacity: 0.85;
  }

  @media screen and (max-width: 544px) {
    #CybotCookiebotDialog {
      max-width: 80vw;
      padding: ${s(Space.L)} ${s(Space.XS)};
    }

    #CybotCookiebotDialogBody {
      padding: 0 ${s(Space.S)};
    }

    #CybotCookiebotDialogBodyContent {
      margin-bottom: ${s(Space.XS)};
    }

    #CybotCookiebotDialogBodyContentTitle {
      font-size: ${s(Space.S)};
      line-height: ${s(Space.S)};
      text-align: center;
    }

    #CybotCookiebotDialogBodyContentText {
      margin-top: ${s(Space.S)};
    }

    .CybotCookiebotDialogBodyButton {
      width: 100%;
      line-height: ${s(Space.S)};
    }

    #CybotCookiebotDialogBodyLevelButtonsRow {
      display: flex;
    }

    #CybotCookiebotDialogBodyLevelButtonsSelectPane {
      padding: 4px;
    }

    .CybotCookiebotDialogBodyLevelButtonWrapper {
      margin-left: 0;
      margin-right: 4px;
    }

    #CybotCookiebotDialogBodyLevelDetailsWrapper {
      padding: 0 4px;
    }

    .CybotCookiebotDialogDetailBodyContentTabsItem,
    .CybotCookiebotDialogDetailBodyContentTabsItemSelected {
      font-size: 12px;
    }
  }
`;
