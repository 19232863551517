import {
  ApolloError,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  useMutation as useApolloMutation,
} from '@apollo/client';
import { DocumentNode, ExecutionResult } from 'graphql';
import { toCamel } from 'utils/utils';

export declare type EctorErrorType = {
  code: string;
  message?: string;
  data?: { [key: string]: any };
  errors?: { code: string; key: string; message: string }[];
};

declare type MutationTuple<TData, TVariables> = [
  (options?: MutationFunctionOptions<TData, TVariables>) => Promise<ExecutionResult<TData>>,
  MutationResult<TData> & { errors: EctorErrorType[] },
];

export const defaultErrorCode = 'defaultError';

export function useMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
  onErrorArg?: (errors: EctorErrorType[]) => void,
): MutationTuple<TData, TVariables> {
  const onError = (apolloError: ApolloError) => {
    if (!onErrorArg) {
      return;
    }

    if (apolloError.networkError) {
      onErrorArg([
        {
          code: defaultErrorCode,
          message: '',
          errors: [],
        },
      ]);

      return;
    }

    const errors = apolloError.graphQLErrors
      ? apolloError.graphQLErrors.map<EctorErrorType>(({ message, extensions }) => {
          if (extensions) {
            return {
              code: toCamel(extensions.code as string),
              data: extensions.exception,
              message,
              errors: [],
            } as EctorErrorType;
          }

          try {
            const ectorError = JSON.parse(message) as EctorErrorType;

            return ectorError;
          } catch (e) {
            return {
              code: defaultErrorCode,
              message: '',
              errors: [],
            };
          }
        })
      : [];
    onErrorArg(errors);
  };
  const [mutateFunction, mutateResult] = useApolloMutation(mutation, {
    ...options,
    onError,
  });
  const { error } = mutateResult;
  const errors = error?.graphQLErrors
    ? error.graphQLErrors.map(err => {
        try {
          return JSON.parse(err.message);
        } catch (e) {
          return { code: 'undefined', message: (err as any).message };
        }
      })
    : [];

  return [mutateFunction, { ...mutateResult, errors }];
}
