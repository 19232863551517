import React, { createContext, useContext, useReducer } from 'react';

enum ModalAction {
  SET_VISIBLE_MODAL = 'SET_VISIBLE_MODAL',
  SET_VISIBLE_MODALS = 'SET_VISIBLE_MODALS',
}

interface ModalStateType {
  [key: string]: boolean;
}

interface ModalActionType {
  type: string;
  modal: {
    type: string;
    visible: boolean;
    ctx?: any;
  };
}

export enum ModalEnum {
  alternativeDate = 'alternativeDate',
  bookingSearchLogin = 'bookingSearchLogin',
  contactUs = 'contactUs',
  customerService = 'customerService',
  discount = 'discount',
  forgottenPassword = 'forgottenPassword',
  impossibleBooking = 'impossibleBooking',
  login = 'login',
  mobilePricing = 'mobilePricing',
  headerPricingForm = 'headerPricingForm',
  ssoLogDifferentUser = 'ssoLogDifferentUser',
  thirdPartyNotice = 'thirdPartyNotice',
  updatePassword = 'updatePassword',
}

export const ModalContext = createContext<[any, any]>([null, null]);

export const ModalReducer = (modals: ModalStateType, action: ModalActionType) => {
  switch (action.type) {
    case ModalAction.SET_VISIBLE_MODAL:
      return {
        ...modals,
        [action.modal.type]: action.modal.visible,
        [`${action.modal.type}_ctx`]: action.modal.ctx,
      };

    default:
      return { ...modals };
  }
};

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const reducer = useReducer<any>(ModalReducer, {});

  return <ModalContext.Provider value={reducer}>{children}</ModalContext.Provider>;
};

export const useModal = (modalName: ModalEnum) => {
  const [state, dispatch] = useContext(ModalContext);
  const isVisible = state ? !!state[modalName] : false;
  const ctx = state ? state[`${modalName}_ctx`] : undefined;

  return {
    isVisible,
    ctx,
    showModal: (ctx?: any) =>
      dispatch({
        type: ModalAction.SET_VISIBLE_MODAL,
        modal: { type: modalName, visible: true, ctx },
      }),
    hideModal: () =>
      dispatch({
        type: ModalAction.SET_VISIBLE_MODAL,
        modal: { type: modalName, visible: false },
      }),
  };
};
